
<template>
    <BackofficeBase :loader_prop="loader_prop">
      <CourierCompanyMenu :courier_company_id_prop="courier_company_id" />
        <div v-if="courier_id == 0">
          <p><b>הוספת שליח</b></p>
        </div>
        <div v-else>
          <p><b>עריכת שליח</b></p>
        </div>
        <div v-if="! show_ty">
          <div class="rtl-class">
            <form @submit="courier_save">
              <div>
                  <div>
                  שם:
                  </div>
                  <div>
                    <input v-model="name" type="text" v-bind:class="{input_error : name_input_error}" />
                    <div class="input_error_txt" v-if="name_input_error">
                      שגיאה שם
                    </div>
                  </div>
                </div>              
                <div>
                  <div>
                    טלפון:
                  </div>
                  <div>
                    <input v-model="phone_number" type="text" v-bind:class="{input_error : phone_number_input_error}" />
                    <div class="input_error_txt" v-if="phone_number_input_error">
                      שגיאה טלפון
                    </div>
                  </div>
                </div>
                <div v-if="courier_id == 0">
                  <div>
                    סיסמא:
                  </div>
                  <div>
                    <input v-model="password" type="password" v-bind:class="{input_error : password_input_error}" />
                    <div class="input_error_txt" v-if="password_input_error">
                      שגיאה בסיסמה
                    </div>
                  </div>
              </div>                
                <div>
                  <div>
                    כתובת דוא"ל:
                  </div>
                  <div>
                    <input v-model="email" type="text" v-bind:class="{input_error : email_input_error}" />
                    <div class="input_error_txt" v-if="email_input_error">
                      שגיאה כתובת דוא"ל
                    </div>
                  </div>
                </div>                
                <div>
                  <div>חסום | 1 חסום | 0 לא חסום:</div>
                    <div>
                      <input v-model="blocked" type="text" v-bind:class="{input_error : blocked_input_error}" />
                      <div class="input_error_txt" v-if="blocked_input_error">שגיאה חסום</div>
                    </div>
                </div>  
                <div class="submit_cont">
                  <input type="submit" value="Save" />
                </div>
            </form>
        </div>
      </div>
      <div v-if=" show_ty">
        בוצע<br>
        <router-link :to="{path : '/backoffice/couriers', 
                          query : {'id' : courier_company_id}}">חזרה</router-link>
      </div>
      <div class="input_error_txt" v-if = " usere_xist ">
      השליח קיים
      </div>
    </BackofficeBase>
  </template>
  
  <script>
  import BackofficeBase from "./BackofficeBase";
  import BackendModel from "../../models/BackendModel";
  import CourierCompanyMenu from "./CourierCompanyMenu";
  
  export default {
    name: 'BackofficeCourierEdit',
    components: {
      BackofficeBase,
      CourierCompanyMenu
    },
    data() {
      return {
        loader_prop : true,
        view_data : [],
        
        courier_id : this.$route.query.courier_id,
        courier_company_id : this.$route.query.courier_company_id,
        
        email : '',
        username : '',

        name : '',
        phone_number :'',

        password : '',

        blocked : '',

        email_input_error : false,
        name_input_error : false,
        phone_number_input_error : false,
        //username_input_error : false,
        password_input_error : false,
        blocked_input_error : false,
        
        show_ty : false,
        usere_xist :false
      }
  
    },
    async mounted() {
      this.courier_company_id = this.$route.query.courier_company_id
      this.courier_id = this.$route.query.courier_id
      if (this.courier_id != '0') {
        let backendModel = new BackendModel()
        let view_data = await backendModel.backendRequest("/Api/service/backoffice/get_courier_to_edit", {"courier_id": this.courier_id})
        this.loader_prop = false
        this.view_data = view_data.data.data
        console.log(this.view_data)
        this.email = this.view_data.email
        this.name = this.view_data.name
        this.phone_number = this.view_data.phone_number
        this.username = this.view_data.username
        this.blocked = this.view_data.blocked
      }else{
        this.loader_prop = false
        this.blocked = 0
      }
      
    },
    methods : {
      async courier_save(e) {
        e.preventDefault()
        // Validation
        let errs = 0
        this.email_input_error = false
        this.name_input_error = false
        this.phone_number_input_error = false
        //this.username_input_error = false
        this.password_input_error = false
        this.blocked_input_error = false

        /*if (this.email == '') {
          this.email_input_error = true
          errs++
        }*/
        if (this.phone_number == '') {
          this.phone_number_input_error = true
          errs++
        }
        if (this.password == '' && this.courier_id == '0') {
          this.password_input_error = true
          errs++
        }
        if (this.name == '') {
          this.name_input_error = true
          errs++
        }
        /*if (this.username == '') {
          this.username_input_error = true
          errs++
        }*/
        if (this.blocked != '0' && this.blocked != '1') {
          this.blocked_input_error = true
          errs++
        }
        if (errs == 0) {
          this.loader_prop = true
          let backendModel = new BackendModel()
          let params = {
            courier_company_id : this.courier_company_id,
            courier_id : this.courier_id,
            name : this.name,
            phone_number : this.phone_number,
            username : this.phone_number,
            email : this.email,
            password : this.password,
            blocked: this.blocked
          }
          let res = await backendModel.backendRequest("/Api/service/backoffice/save_courier", params)
          if(res.data.res == "NOK"){
            console.log(res.data.res )
            this.loader_prop = false
            this.usere_xist = true
          }else{
            this.loader_prop = false
            this.show_ty = true
          }
        }
      }
    }
  }
  </script>
  
  <style scoped>
  @import "../../assets/style.css"
  </style>
  
  